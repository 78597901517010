@import "setting";
@import "respinsive";
@import "customizeAndt";
@import "sideBar";
@import "nprogress";

body {
  font-family: sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.5em;
  font-weight: 700;
}

a {
  color: #1890ff;
}

.line {
  height: 1px;
  background-color: rgb(235, 235, 235);
}
